import React from 'react';
import { Helmet, HelmetData } from 'react-helmet-async';

import './style.css';

const helmetData = new HelmetData({});
// const { helmet } = helmetData.context;

export default function Resume() {
    return (
        <div className="container container-constrained resume">
            
            <Helmet helmetData={helmetData}>
                <title>Resume | Alex Duckmanton</title>
                <meta name="description" content="I've done some cool things" />
            </Helmet>

            <section className="resume-entry">
                <h2>Woolworths</h2>
                <h3>
                    Senior Product Designer
                    <small>February 2024 — Present</small>
                </h3>
                <p>I worked on the product design for Scan&Go Trolleys — a tablet-sized device used in-store at Woolies. Customers attach a tablet to their trolley, scan their items throughout the store, and then pay directly on the trolley. It's a self-checkout on wheels!</p>
                <p>From the success of Scan&Go Trolleys, I was tapped to work on a new (and under NDA) project for Woolworths. This involved rapid design sprints, prototyping and user testing to support a vision for the future of a critical touch point. This project will be reviewed among C-Suite execs at the highest level of the business.</p>
                <ul>
                    <li><p>Responsible for UX & UI design for Scan&Go Trolleys</p></li>
                    <li><p>Built high-fidelity prototypes in Figma for user testing</p></li>
                    <li><p>Planned and facilitated in-person user testing</p></li>
                    <li><p>Facilitated workshops</p></li>
                    <li><p>Presented to senior stakeholders</p></li>
                    <li><p>Delivered high-fidelity screens & flows in Figma & Miro</p></li>
                    <li><p>Ongoing support & handover with developers</p></li>
                </ul>
            </section>

            <section className="resume-entry">
                <h2>Mathspace</h2>
                <h3>
                    Head of Design
                    <small>January 2022 — December 2023</small>
                </h3>
                <p>Mathspace is an online, personalized mathematics instruction, learning and assessment platform. Its platform tailors mathematics learning pathways for students based on their knowledge gaps. Teachers can assign practice for their students, and review their progress as it aligns to their local curriculum.</p>
                <ul>
                    <li><p>Lead a team of six designers</p></li>
                    <li><p>Developed high-level concepts to set future product roadmaps</p></li>
                    <li><p>Facilitated regular customer interviews and usability studies, with both adults and children</p></li>
                    <li><p>Facilitated large-scale workshops among internal stakeholders</p></li>
                    <li><p>Created high-fidelity prototypes with Protopie</p></li>
                    <li><p>Supported designers in career and skill development</p></li>
                    <li><p>Worked closely with founders and product owners to develop a roadmaps</p></li>
                </ul>
            </section>

            <section className="resume-entry">
                <h2>Stan</h2>
                <h3>
                    Product Design Lead
                    <small>March 2020 — December 2021</small>
                </h3>
                <p>Stan is Australia's leading local subscription video-on-demand provider, backed by media powerhouse Nine Entertainment Company. As a product designer, I worked on apps for various TV platforms, and the web.</p>
                <ul>
                    <li><p>Lead product design team of three</p></li>
                    <li><p>Lead product design for the launch of Stan Sport</p></li>
                    <li><p>Created and managed processes for ongoing asset creation to support Stan Sport</p></li>
                    <li><p>Facilitated ideation workshops</p></li>
                    <li><p>Planned, facilitated, and reported on usability testing sessions</p></li>
                    <li><p>Presented concepts to senior stakeholders</p></li>
                    <li><p>Created highly dynamic interactive prototypes with Protopie</p></li>
                    <li><p>Created detailed, annotated wireframes and UX flows</p></li>
                    <li><p>Delivered high-fidelity UI screens with Figma</p></li>
                    <li><p>Liaised with and supported developers with ongoing design handover</p></li>
                </ul>
            </section>

            <section className="resume-entry">
                <h2>WooliesX</h2>
                <h3>
                    Senior UX/UI Designer
                    <small>June 2019 — March 2020</small>
                </h3>
                <p>WooliesX is the technology arm of Woolworths Australia, producing the majority of its digital content and products to support the wider business. As a member of the mobile app team, I work on the Woolworths shopping app for both iOS and Android.</p>
                <ul>
                    <li><p>Facilitated ideation workshops</p></li>
                    <li><p>Planned, facilitated, and reported on usability testing sessions</p></li>
                    <li><p>Created highly dynamic interactive prototypes with Protopie</p></li>
                    <li><p>Created detailed, annotated wireframes and screens with Miro</p></li>
                    <li><p>Delivered high-fidelity UI screens with Sketch</p></li>
                    <li><p>Liaised with and supported developers with ongoing design handover</p></li>
                </ul>
            </section>

            <section className="resume-entry">
                <h2>Massive Interactive</h2>
                <h3>
                    Senior Designer
                    <small>June 2016 — June 2019 (Contract)</small>
                </h3>
                <p>Massive is a global technology company, recognised and awarded for powering the world's best multiscreen video platforms and solutions. As part of the UX&D team, I've worked on projects ranging from multi-platform video-on-demand apps to complex financial services portals.</p>
                <ul>
                    <li><p>Clients included AT&T, Rest, Qantas, Optus, and more.</p></li>
                    <li><p>Facilitated strategy and kickoff workshops</p></li>
                    <li><p>Planned, prototyped, and evaluated detailed usability studies</p></li>
                    <li><p>Wrote in-depth functional UX specifications and high-level product strategy documentation</p></li>
                    <li><p>Created pixel-perfect UI screens and meticulous UI kits with detailed component states with Sketch</p></li>
                    <li><p>Liaised with developers via handover sessions and for ongoing support</p></li>
                    <li><p>Presented work to senior and executive-level stakeholders</p></li>
                </ul>
            </section>

            <section className="resume-entry">
                <h2>1stAvailable</h2>
                <h3>
                    Head of Design & UX
                    <small>September 2014 — March 2016</small>
                </h3>
                <p>1stAvailable is an online healthcare booking service. Joining at a critical time for the business, I lead a full product redesign included wireframing, visual design and front-end development using AngularJS.</p>
                <ul>
                    <li><p>Researched, designed, prototyped, and tested new product ideas</p></li>
                    <li><p>Met with key stakeholders to evaluate new and existing product offerings</p></li>
                    <li><p>Redesigned and developed existing product, resulting in a more modern visual identity and simplified UX.</p></li>
                </ul>
            </section>

            <section className="resume-entry">
                <h2>SiteMinder</h2>
                <h3>
                    UX Designer
                    <small>July 2014 — September 2014</small>
                </h3>
                <p>Siteminder are a leading cloud platform for hotels, allowing allows hotels to attract, reach and convert guests across the globe.</p>
                <p>As a UX designer on the Little Hotelier team I worked to review the existing product from a user's point of view, created and user-tested prototypes for new features, and collaborated on planning for the product's future.</p>
            </section>

            <section className="resume-entry">
                <h2>PlayStation</h2>
                <h3>
                    Senior Web Designer
                    <small>March 2012 — March 2014</small>
                </h3>
                <p>I worked on DevNet - the global PlayStation developer support platform. It facilitates developer support, internal communication, documentation, and the management of several processes key to developing and publishing for PlayStation.</p>
                <p>Spearheaded a site-wide redesign, from initial wireframes to high-fidelity comps to implementation. Along the way the design was informed by existing metrics, user interviews, card sorting, user testing, and stakeholder feedback.</p>
                <p>Created concepts for the future of the PlayStation developer experience. This involved gathering requirements from stakeholders in three regions globally, generating designs, user-testing, and hosting weekly reviews for each region.</p>
            </section>

            <section className="resume-entry">
                <h2>NVBI</h2>
                <h3>
                    Designer
                    <small>August 2011 — March 2012</small>
                </h3>
                <p>Direct marketing agency who take products from initial development through to sales & marketing.</p>
                <ul>
                    <li><p>Redesigned existing web, print, and product material</p></li>
                    <li><p>Designed initial concepts for a mobile e-commerce store.</p></li>
                    <li><p>Logo and branding design concepts for new products</p></li>
                </ul>
            </section>

            <section className="resume-entry">
                <h2>Visible Results</h2>
                <h3>
                    Head of Creative
                    <small>April 2009 — May 2011</small>
                </h3>
                <p>Visible Results were a full-service loyalty marketing agency. After a briefly working casually at Visible Results while studying at university, I was asked to join the team full-time as their Head of Creative.</p>
                <ul>
                    <li><p>Designed, developed and tested eDMs within very tight deadlines.</p></li>
                    <li><p>Designed and developed Bras N Things Next Model - a competition designed to launch Bras N Things' Facebook page. The campaign spanned a microsite, Facebook app, eDMs & collateral</p></li>
                    <li><p>Created concept designs and diagrams for use in business development presentations & pitches.</p></li>
                    <li><p>Managed the time of another designer based on upcoming workload</p></li>
                </ul>
            </section>
        </div>
    );
}
