import React from 'react';
import { Helmet, HelmetData } from 'react-helmet-async';

import Nav from '../Nav';

import './style.css';

const helmetData = new HelmetData({});

function Content(props) {
    return (
        <div className="hero-home">
            <Helmet helmetData={helmetData}>
                <title>{props.title}</title>
            </Helmet>

            <span className={'hero-icon hero-icon-' + props.page} />

            <h1 className="home-title">{props.title}</h1>
            <h2 className="home-subtitle" dangerouslySetInnerHTML={{__html: props.subtitle}} />
            <p className="home-blurb" dangerouslySetInnerHTML={{__html: props.body}} />
        </div>
    );
}

export default function Hero(props) {
    return (
        <section className="hero">
            <Helmet helmetData={helmetData}>
                <title>Alex Duckmanton</title>
                <meta name="description" content="I'm a product designer based in Sydney" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>

            {props.page === 'home' &&
                <Content
                page="home"
                title="Alex Duckmanton"
                subtitle={'UX <span class="faded">/</span> UI <span class="faded">/</span> Product Designer'}
                body="I'm a pretty cool guy with over 15 years' experience working as a product designer. I'll help you with product strategy, UX/UI design, and even front-end development."
            />
            }

            {props.page === 'resume' &&
                <Content
                    page="resume"
                    title="Resume"
                    subtitle="I've had a few cool jobs"
                    body="On top of my day-to-day work, I also like to pick up freelance jobs when I can – I've worked with Barnardo's in the UK and community radio station 3RRR in Melbourne."
                />
            }

            {props.page === 'error' && 
                <Content
                    page="error"
                    title="404"
                    subtitle="Oh no"
                    body="Are you lost?"
                />
            }

            <Nav className="home" page={props.page} />

        </section>
    );
}
