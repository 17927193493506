import React from 'react';

import {
    Trolleys,
    Mathspace,
    Stan,
    Optus,
    Woolies,
    Rest,
    Burst,
    Beetroot,
    Att,
    Fa,
    Playstation,
    Thumbcinema
} from './Article';

import './preview.css';

export default function Preview({ article }) {
    return (
        <>
            <div className={'preview ' + article}>
                <ArticlePreview article={article} />
            </div>
        </>
    )
}

function ArticlePreview({ article }) {
    var ArticleComponent;

    if (!article) ArticleComponent = false;
    else if (article === 'trolleys')    ArticleComponent = Trolleys;
    else if (article === 'mathspace')   ArticleComponent = Mathspace;
    else if (article === 'stan')        ArticleComponent = Stan;
    else if (article === 'optus')       ArticleComponent = Optus;
    else if (article === 'woolies')     ArticleComponent = Woolies;
    else if (article === 'rest')        ArticleComponent = Rest;
    else if (article === 'burst')       ArticleComponent = Burst;
    else if (article === 'beetroot')    ArticleComponent = Beetroot;
    else if (article === 'att')         ArticleComponent = Att;
    else if (article === 'fa')          ArticleComponent = Fa;
    else if (article === 'playstation') ArticleComponent = Playstation;
    else if (article === 'thumbcinema') ArticleComponent = Thumbcinema;

    if (!ArticleComponent) return;
    else return <ArticleComponent id={article} isPreview />;
}