import React from 'react';
import { ScrollRestoration, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import './normalize.css';
import './style.css';

import Hero from '../Hero';
import Footer from '../Footer';
// import Expertise from '../Expertise';
import Resume from '../Resume';
import Work from '../Work';

export default function App() {
    let location = useLocation();

    React.useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search, title: 'Home' });
    }, [location]);

    return (
        <div className="app">
            <Hero page="home" />
            {/* <Expertise /> */}
            <Work />
            <Footer />
        </div>
    );
}

export function ResumeApp() {
    let location = useLocation();

    React.useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search, title: 'Resume' });
    }, [location]);

    return (
        <div className="app">
            <Hero page="resume" />
            <Resume />
            <Footer />
            <ScrollRestoration />
        </div>
    )
}

export function Error() {
    let location = useLocation();

    React.useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search, title: 'Error' });
    }, [location]);

    return <Hero page="error" />;
}
