import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Scroll from 'react-scroll';
import ReactGA from 'react-ga4';

import './style.css';

// var expertiseOffset = window.innerWidth < 769 ? -20 : -200;
// var workOffset = window.innerWidth < 769 ? 0 : window.innerHeight * .667 - 85;

var expertiseOffset = function() {
    if (window.innerWidth < 720) return -20;
    if (window.innerWidth < 1000) return -100;
    return -200;
}

var workOffset = function() {
    if (window.innerWidth < 1000) return -8;
    return -16;
}

export default class Nav extends Component {
    state = {}

    GASendExternalLink = (label) => {
        ReactGA.event(
            'page_view',
            {
                'page_title': label,
                category: 'External',
            }
        )
    }

    focusExpertise = () => {
        window.setTimeout(() => {
            Scroll.scroller.scrollTo('expertise', {
                delay: 250,
                duration: 666,
                offset: expertiseOffset(),
                smooth: 'easeInOutQuint'
            });
        }, 50)
    }

    // focusWork = () => {
    //     window.setTimeout(() => {
    //         Scroll.scroller.scrollTo('work', {
    //             delay: 250,
    //             duration: 666,
    //             offset: workOffset(),
    //             smooth: 'easeInOutQuint'
    //         });
    //     }, 50)
    // }


    // LIFECYCLE
    componentDidMount = () => {
        this.setState({ canViewDetails: true });
    }


    // RENDERING
    render() {
        const customClass = this.props.className || '';
        const email = this.state.canViewDetails ? 'alexduckmanton@gmail.com' : 'nospam@turnonjavascript.com';

        return (
            <nav className={'nav ' + customClass}>

                {this.props.page === 'home' ?
                    <>
                        {/* <Scroll.Link
                            className="nav-link"
                            href="#expertise"
                            to="expertise"
                            offset={expertiseOffset()}
                            spy={true}
                            smooth="easeInOutQuint"
                            duration={666}
                            >
                                Expertise
                        </Scroll.Link> */}
                        <Scroll.Link
                            className="nav-link"
                            href="#work"
                            to="work"
                            offset={workOffset()}
                            spy={true}
                            smooth="easeInOutQuint"
                            duration={666}
                            >
                                Work
                        </Scroll.Link>
                    </>
                    :
                    <>
                        {/* <Link className="nav-link" to="/" onClick={this.focusExpertise}>Expertise</Link> */}
                        <Link className="nav-link" to="/">Work</Link>
                    </>
                }

                <NavLink className="nav-link" to="/resume">Resume</NavLink>
                <a className="nav-link" href={'mailto:' + email} target="_blank" rel="noopener noreferrer" onClick={() => { this.GASendExternalLink('Email') }}>Email</a>
                <a className="nav-link" href="https://www.linkedin.com/in/alexduckmanton/" target="_blank" rel="noopener noreferrer" onClick={() => { this.GASendExternalLink('LinkedIn') }}>LinkedIn</a>
            </nav>
        );
    }
}
