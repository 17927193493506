import React, { Component } from 'react';

import Preview from './Preview';

import './themes.css';
import './style.css';

const DESKTOP_WIDTH_THRESHOLD = 750;

class Work extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isDesktop: window.innerWidth >= DESKTOP_WIDTH_THRESHOLD,
        }
    }

    addResizeListener = () => {
        if (this.state.isDesktop) return;

        window.addEventListener('resize', this.resizeListener);
    }

    resizeListener = () => {
        if (this.state.isDesktop) {
            window.removeEventListener('resize', this.resizeListener);
            return;
        }

        // flag prevents the check function to run simultaneously if/when the event fires before it's done
        if (!this.checkingSize) {
            window.requestAnimationFrame(() => {
                var width = window.innerWidth;
                // console.log(width);
                if (width >= DESKTOP_WIDTH_THRESHOLD) this.setState({
                    isDesktop: true
                })
                this.checkingSize = false;
            });
        }

        // flip the flag so that the above function won't run again until it's finished
        this.checkingSize = true;
    }


    // LIFECYCLE
    componentDidMount = () => {
        if (!this.state.isDesktop) this.addResizeListener();
    }


    // RENDERING
    render() {
        return (
            <section className="work" id="work" name="work">
                {/* <Preview article="trolleys" /> */}
                <Preview article="mathspace" />
                <Preview article="stan" />
                <Preview article="optus" />
                <Preview article="woolies" />
                <Preview article="burst" />
                <Preview article="beetroot" />
                <Preview article="rest" />
                <Preview article="att" />
                <Preview article="fa" />
                <Preview article="playstation" />
                <Preview article="thumbcinema" />
            </section>
        );
    }
}

export default Work;
