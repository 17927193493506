import React from 'react';

import './style.css';

export default function Footer() {
    return (
        <div className="container container-constrained footer">
            <h2 className="footer-title">
                You made it to the end
            </h2>
            <p><a className="nav-link" href="mailto:alexduckmanton@gmail.com" target="_blank" rel="noopener noreferrer">Shoot me an email</a> and let's make something together</p>
        </div>
    );
}
