import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactGA from 'react-ga4';

import App, { ResumeApp as Resume, Error } from './components/App';
import Article, { ArticleContainer, loader as articleLoader } from './components/Work/Article';

import './index.css';
// import './fonts/Inter.var.woff2';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />
  },
  {
    path: "/resume/*",
    element: <Resume />,
    errorElement: <Error />
  },
  {
    path: "/article/*",
    element: <ArticleContainer />,
    errorElement: <Error />,
    loader: articleLoader,
    children: [
      {
        path: ":article/*",
        element: <Article />,
        loader: articleLoader
      }
    ]
  }
]);

const container = document.getElementById('root');
var root;
if (container.hasChildNodes()) {
  root = ReactDOM.hydrateRoot(container, <RouterProvider router={router} /> );
} else {
  root = ReactDOM.createRoot(container);
  root.render( <RouterProvider router={router} /> );
}

// initialise google analytics
ReactGA.initialize('G-SCNLESEKR3', {
  testMode: window.location.host.match('localhost') ? true : false
});