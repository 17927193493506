import React from 'react';

import './feedback.css';

export default function Feedback({ items }) {
    function writeStars(num) {
        var stars = '';
        for (var i = 0; i < num; i++) {
            stars += '★';
        }

        return stars;
    }

    return (
        <div className="feedback">
            {items.map((item, i) => {

                return (
                    <blockquote className="feedback-item" key={i}>
                        {item.stars &&
                            <p className="feedback-content feedback-stars">{writeStars(item.stars)}</p>
                        }

                        {item.titleLink ?
                            <p className="feedback-content"><a href={item.titleLink} target="_blank" rel="noopener noreferrer"><strong>{item.title}</strong></a></p>
                            :
                            item.title && <p className="feedback-content"><strong>{item.title}</strong></p>
                        }

                        {item.content &&
                            <p className="feedback-content feedback-body">{item.content}</p>
                        }

                        {item.authorLink ?
                            <p className="feedback-content">— <a href={item.authorLink} target="_blank" rel="noopener noreferrer">{item.author}</a></p>
                            :
                            item.author && <p className="feedback-content">{'— ' + item.author}</p>
                        }
                    </blockquote>
                )
            })}
        </div>
    )
}
